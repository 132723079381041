/* eslint-disable @typescript-eslint/indent */
/* eslint-disable react/jsx-indent */
import React, { FC, useState, useRef, useEffect } from 'react'
import { NavLink, useHistory } from 'react-router-dom'
import { ButtonBase, Snackbar } from '@material-ui/core'
import { Dispatch } from 'redux'
import { useDispatch, useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { useTracking } from 'react-tracking'
import ExitToAppIcon from '@material-ui/icons/ExitToApp'
import HelpOutlineIcon from '@material-ui/icons/HelpOutline'
import HistoryIcon from '@material-ui/icons/History'
import AccountTreeIcon from '@material-ui/icons/AccountTree'
import LanguageIcon from '@material-ui/icons/Language'
import { IdList } from '../../../core/utils/id-list'
import { RoutePath } from '../../../core/routes/route-path'
import { selectAuth } from '../../../auth/selectors'
import { signOut } from '../../../auth/actions'
import BaseDrawer from '../../BaseDrawer/BaseDrawer'
import { selectUser } from '../../../user/selectors'
import ConfirmationIco from '../../customIcons/ConfirmationIco'
import PersonAddAltIcon from '@material-ui/icons/PersonAddOutlined'
import { selectProviderList } from '../../../provider/selectors'
import { changeTab } from '../../../config/actions'
import { TabsName } from '../../../core/tabsName'
import { selectConfig } from '../../../config/selectors'
import callUsIcon from '../../../../assets/images/callusIcon.png'
import { setTutorialOn } from '../../../tutorial/actions'
import { selectCampaignReferral } from '../../../campaign/selectors'
import './NavigationDrawer.scss'
import { languageService } from '../../../language/service'
import i18n from 'i18next'
import {
  DEFAULT_LANGUAGE_CODE,
  DEFAULT_LANGUAGE_NAME
} from '../../../../locales/i18n'
import PersonOutlineIcon from '@material-ui/icons/PersonOutline'
import * as Sentry from '@sentry/react'
import { UserStatus } from '../../../user/userStatus'
import { AnalyticsCategory } from '../../../core/analytics/analyticsCategory'

const CUSTOME_USER_ID_LENGTH = 6
interface NavigationDrawerProps {
  menuOpen: boolean
  setMenuOpen: (menuOpen: boolean) => void
  setHistoryOpen: (menuOpen: boolean) => void
  setLanguageOpen: (menuOpen: boolean) => void
  setMyAccountOpen: (menuOpen: boolean) => void
}

const NavigationDrawer: FC<NavigationDrawerProps> = ({
  menuOpen,
  setMenuOpen,
  setHistoryOpen,
  setLanguageOpen,
  setMyAccountOpen
}) => {
  const { t } = useTranslation()
  const dispatch: Dispatch<UserAction> = useDispatch()
  const history = useHistory()
  const { trackEvent } = useTracking()
  const auth: AuthState = useSelector(selectAuth)
  const user: UserState = useSelector(selectUser)
  const config: ConfigState = useSelector(selectConfig)
  const providerList: ProviderAccount[] = useSelector(selectProviderList)
  const [isCopiedOpen, setIsCopiedOpen] = useState<boolean>(false)
  const [isIDCopiedOpen, setIsIDCopiedOpen] = useState<boolean>(false)
  const textCopyRef = useRef<any>()
  const userIDCopyRef = useRef<any>()
  const referralInfo = useSelector(selectCampaignReferral)
  const currentLanguage: string = languageService.getLanguageTitle()
  const handleSignOut = async (): Promise<any> => {
    await dispatch(signOut())
    history.push(RoutePath.SignIn)
    setMenuOpen(false)
  }
  const handleCallUsAt = (): void => {
    trackEvent({
      page: AnalyticsCategory.Menu,
      action: 'Menu Call Us',
      payload: {
        origin: 'more menu'
      }
    })
    textCopyRef.current?.select()
    document.execCommand('copy')
    window.getSelection()?.removeAllRanges()
    setIsCopiedOpen(true)
  }

  const handleHistory = (): void => {
    trackEvent({
      page: AnalyticsCategory.PTH,
      action: 'PTH View',
      payload: {
        origin: 'more menu'
      }
    })
    setMenuOpen(false)
    setHistoryOpen(true)
  }

  const handleInviteCode = (): void => {
    setMenuOpen(false)
    dispatch(changeTab(TabsName.More))
  }

  const handleMyAccount = (): void => {
    setMenuOpen(false)
    setMyAccountOpen(true)
  }

  const handleConnectAccount = (): void => {
    trackEvent({
      page: AnalyticsCategory.Menu,
      action: 'Menu Connect Account'
    })
    setMenuOpen(false)
    dispatch(changeTab(TabsName.More))
  }

  const handleReferFriend = (): void => {
    trackEvent({
      page: AnalyticsCategory.Menu,
      action: 'Menu Refer a Friend'
    })
    setMenuOpen(false)
    dispatch(changeTab(TabsName.More))
  }

  const handleHelp = (): void => {
    trackEvent({
      page: AnalyticsCategory.Menu,
      action: 'Menu Help',
      payload: {
        origin: 'more menu'
      }
    })
    setMenuOpen(false)
  }
  const handleLanguage = (): void => {
    setMenuOpen(false)
    setLanguageOpen(true)
  }

  const copyUserIDToClipboard: () => void = () => {
    userIDCopyRef.current?.select()
    document.execCommand('copy')
    setIsIDCopiedOpen(true)
  }
  useEffect(() => {
    if (!config.SupportLanguage) {
      languageService.setLanguage(DEFAULT_LANGUAGE_CODE, DEFAULT_LANGUAGE_NAME)
      const setDefaultLng = async (): Promise<void> => {
        await i18n.changeLanguage(DEFAULT_LANGUAGE_CODE)
      }
      setDefaultLng().catch((error: Error) => Sentry.captureException(error, {
        tags: {
          method: 'setDefaultLng'
        }
      }))
    }
  }, [config.SupportLanguage])
  const shortenUserID = (userID: string | undefined): string => {
    return userID != null ? userID?.toString().substring(0, CUSTOME_USER_ID_LENGTH) : ''
  }
  return (
    <BaseDrawer isOpen={menuOpen} onClose={() => setMenuOpen(false)}>
      {config.SupportLanguage && (
        <a
          id={IdList.multiLanguageSelection}
          onClick={handleLanguage}
          className='base-drawer__link'
          role='button'
          aria-label='Language Selection'
        >
          <div className='flex align-center language_drawer' role='presentation'>
            <LanguageIcon className='base-drawer__link__ico' />
            <span className='base-drawer__link__txt'>{t`Language`}</span>
            <span className='base-drawer__link__txt current_langauge'>
              {t([currentLanguage])}
            </span>
          </div>
        </a>
      )}

      {auth.isAuth && config.IsStandalonModeSupported && (
        <ButtonBase
          id={IdList.manageAccount}
          className='base-drawer__link'
          onClick={handleMyAccount}
        >
          <div className='flex align-center'>
            <PersonOutlineIcon className='base-drawer__link__ico' />
            <span className='base-drawer__link__txt'>{t`MyAccount`}</span>
          </div>
        </ButtonBase>
      )}

      {providerList.length > 0 && (
        <NavLink
          id={IdList.navModalConnect}
          to={RoutePath.ConnectAccount}
          className='base-drawer__link'
          onClick={handleConnectAccount}
        >
          <div className='flex align-center'>
            <AccountTreeIcon className='base-drawer__link__ico' />
            <span className='base-drawer__link__txt'>{t`ConnectAccounts`}</span>
          </div>
        </NavLink>
      )}

      {(user.earningsCount > 0 || user.redeemsCount > 0) && (
        <a
          id={IdList.navModalHistory}
          onClick={handleHistory}
          className='base-drawer__link'
          role='button'
          aria-label='Transaction History'
        >
          <div className='flex align-center' role='presentation'>
            <HistoryIcon className='base-drawer__link__ico' />
            <span className='base-drawer__link__txt'>{t`TransactionHistory`}</span>
          </div>
        </a>
      )}

      <NavLink
        id={IdList.navModalInviteCode}
        to={RoutePath.Invite}
        className='base-drawer__link'
        onClick={handleInviteCode}
      >
        <div className='flex align-center'>
          <ConfirmationIco />
          <span className='base-drawer__link__txt'>{t`PromoCode`}</span>
        </div>
      </NavLink>
      {config.IsReferralAvailable &&
        referralInfo != null &&
        referralInfo.ReferralConfiguration != null && (
          <NavLink
            id={IdList.navModalReferFriend}
            to={RoutePath.Refer}
            className='base-drawer__link'
            onClick={handleReferFriend}
          >
            <div className='flex align-center'>
              <PersonAddAltIcon className='base-drawer__link__ico referralIcon' />
              <span className='base-drawer__link__txt'>{t`ReferFriend`}</span>
            </div>
          </NavLink>
        )}
      {!config.DisableTutorial && (
        <ButtonBase
          id={IdList.navModalSignOut}
          className='base-drawer__link'
          onClick={() => {
            trackEvent({
              action: 'Menu Tutorial',
              category: AnalyticsCategory.Menu
            })
            dispatch(changeTab(TabsName.Earn))
            history.replace(RoutePath.Home)
            setMenuOpen(false)
            dispatch(setTutorialOn(true))
            trackEvent({
              action: 'Start Tutorial',
              category: 'Tutorial',
              payload: {
                Origin: 'Menu'
              }
            })
          }}
        >
          <div className='flex align-center'>
            <HelpOutlineIcon className='base-drawer__link__ico' />
            <span className='base-drawer__link__txt'>{t`tutoMenu`}</span>
          </div>
        </ButtonBase>
      )}
      {!config.DisableHelp && (
        <a
          id={IdList.navModalHelp}
          href={config.CustomerSupportLink}
          target='_blank'
          className='base-drawer__link'
          onClick={handleHelp}
          rel='noreferrer'
        >
          <div className='flex align-center'>
            <HelpOutlineIcon className='base-drawer__link__ico' />
            <span className='base-drawer__link__txt'>{t`Help`}</span>
          </div>
        </a>
      )}
      {config.SupportPhoneNumber != null &&
        config.SupportPhoneNumber.length > 0 &&
        user.userStatus === UserStatus.Verified && (
          <ButtonBase
            id={IdList.navModalSignOut}
            className='base-drawer__link'
            onClick={handleCallUsAt}
          >
            <div className='flex align-center'>
              <img
                src={callUsIcon}
                className='base-drawer__link__ico'
                alt='Call Us Icon'
              />
              <span className='base-drawer__link__txt'>
                {t`CallUsAt`}
                <input
                  ref={textCopyRef}
                  className='base-drawer__link__txt text__width'
                  type='text'
                  readOnly
                  value={config.SupportPhoneNumber}
                  style={{
                    border: 'none',
                    padding: 0,
                    margin: 0,
                    outline: 'none',
                    cursor: 'pointer'
                  }}
                />
              </span>
            </div>
          </ButtonBase>
        )}
      <Snackbar
        open={isCopiedOpen}
        autoHideDuration={1500}
        onClose={() => {
          setIsCopiedOpen(false)
        }}
        message={t`PhoneCopied`}
        ContentProps={{ style: {} }}
      />

      {auth.isAuth && config.IsStandalonModeSupported && (
        <ButtonBase
          id={IdList.navModalSignOut}
          className='base-drawer__link'
          onClick={handleSignOut}
        >
          <div className='flex align-center'>
            <ExitToAppIcon className='base-drawer__link__ico' />
            <span className='base-drawer__link__txt'>{t`LogOut`}</span>
          </div>
        </ButtonBase>
      )}
      {config.IsUserIDDisplay && (
        <ButtonBase
          id={user.UserID}
          onClick={copyUserIDToClipboard}
          className='base-drawer__link'
        >
          <input
            ref={userIDCopyRef}
            className='user-id-copy-input'
            type='text'
            readOnly
            value={`ID: ${shortenUserID(user.UserID)}`}
            style={{
              border: 'none',
              outline: 'none'
            }}
          />
        </ButtonBase>
      )}
      <Snackbar
        open={isIDCopiedOpen}
        autoHideDuration={1500}
        onClose={() => {
          setIsIDCopiedOpen(false)
        }}
        message={t`IDCopied`}
        ContentProps={{ style: {} }}
      />
    </BaseDrawer>
  )
}

export default NavigationDrawer
